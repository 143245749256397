#home-pink-bg {
	position: relative;
	height: calc(min(550px, 70vw));
	width: 100%;
	margin-bottom: 130px;
	box-shadow: 0 80px #fcebeb;

	#home-pink-section {
		position: relative;
		width: 650px;
		max-width: 80vw;
		height: 100%;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	#home-headshot-image {
		width: 100%;
		position: absolute;
		bottom: -80px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}

#home-intro-shapes {
	position: relative;

	#home-photo-shadow {
		width: 77%;
		height: 66%;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: #fcebeb;
	}

	#home-photo {
		width: 77%;
		position: absolute;
		right: 11.5%;
		top: 18%;
	}

	#home-triangle-svg {
		width: 35.907%;
		height: 35.2746%;
		position: absolute;
		right: 0;
		top: 0;
	}

	#home-triangle-polygon {
		fill: #999;
	}
}

#home-intro-text {
	padding-inline-start: 10%;
	padding-inline-end: 10%;
	vertical-align: middle;

	#home-what-are {
		font-family: 'Dawning of a New Day', cursive;
		font-size: 2em;
	}
}

/* XXS-XS */
@media(max-device-width: 575px) {
	#home-what-are {
		font-size: 1.7em;
	}
}

/* XXS-S */
@media(max-device-width: 767px) {
	//#home-pink-bg {
	//	height: calc(min(550px, 60vw));
	//	margin-bottom: 100px;
	//	box-shadow: 0 50px #fcebeb;
	//
	//	#home-headshot-image {
	//		bottom: -50px;
	//	}
	//}

	#home-intro-shapes {
		width: 300px;
		max-width: calc(80vw - 60px);
		height: 350px;
		max-height: calc((7/6) * (80vw - 60px));
	}
}

/* M+ */
@media(min-device-width: 768px) {
	#home-intro-shapes {
		width: 42vw;
		height: 49vw;
		max-width: 600px;
		max-height: 700px;
	}
}