.Footer {
    background-color: #737373;
    color: white;
    width: 100%;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-block-start: 20px;
    margin-block-start: 100px;

    .row {
        margin-inline-start: 5%;
        margin-inline-end: 5%;
        margin-block-end: 10px;
    }
    .col {
        margin-inline: 1vw;
    }
    .Footer-media-icon {
        height: 1.5em;
        margin-block-end: 16px;
        margin-inline-start: 2px;
        margin-inline-end: 2px;
    }
}


@media(max-device-width: 576px) {
    .Footer {
        .Footer-media-icon {
            height: 2em;
            margin-inline-end: 8px;
        }
    }
}

@media(min-device-width: 577px) {
    .Footer {
        .Footer-contact {
            text-align: start;
        }
        .Footer-follow {
            text-align: center;
        }
        .Footer-visit {
            text-align: end;
        }
    }
}