.NavBar {
    padding-inline-start: 5vw;
    padding-inline-end: 5vw;
    padding-block-start: 20px;
    padding-block-end: 20px;
    margin-block-end: 20px;
    background-color: #fff8f8;
} .NavBar #home-logo {
      width: 160px;
      margin-bottom: 8px;
  } .NavBar #nav-links a {
        color: #444;
        margin-inline-end: 7vw;
        padding-block: 2px;
        text-decoration: none;
        border-bottom: 3px solid #fcd7d700;
    } .NavBar #nav-links a:hover {
          color: black;
          border-bottom-color: #fcd7d7;
          transition: color 200ms, border-bottom-color 500ms;
      } .NavBar #nav-links a:active {
            transition: color 0ms, border-bottom-color 0ms;
        }

.NavBar #nav-links .nav-item-end {
    margin-inline-end: 0;
}

.NavBar #booking-btn {
    background-color: #fcd7d7;
    border-radius: 0.25rem;
    border: 1px solid #fcd7d7;
    padding: 0.2rem 0.35rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: black;
    text-decoration: none;
}
.NavBar #booking-btn:hover {
    background-color: white !important;
}
