.EmbeddedSchedule {
	iframe {
		width: 80vw;
		min-width: 300px;
		margin-left: 10vw;
	}
	min-height: 80vh;
	margin-bottom: -100px;
}

/* Extra Small (mobile) */
@media(max-device-width: 575px) {
	.EmbeddedSchedule {
		iframe {
			width: 90vw;
			margin-left: 5vw;
		}
	}
}
