@import "custom";
@import url('https://fonts.googleapis.com/css?family=Dawning+of+a+New+Day');

.App {
    min-height: 100vh;
    color: #444;
}

.content-body {
    margin-top: 50px;
}

.page-content-wrapper {
    display: flex;
    margin-inline: 6vw;
}

.page-content {
    max-width: 1500px;
    margin: auto;
    position: relative;
}

.App-grid-wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
}

a {
    outline: 0;
}

body {
    font-family: "Avenir Next Condensed", sans-serif;
    font-size: 1.1em;
    margin: 0;
    overflow-x: hidden;
    min-height: 100%;
}

h4 {
    font-weight: normal;
    font-size: .9em;
}

.small-text {
    font-size: .8em;
}

@mixin script {
    font-family: 'Dawning of a New Day', cursive;
}

.script {
    @include script;
    font-size: 1.3em;
}

.label {
    font-size: .8em;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

/* XXS (tiny phone) */
@media(max-device-width: 350px) {
}

/* Extra Small (mobile) */
@media(max-device-width: 576px) {
}

/* Small (large phone/small tablet in portrait) */
@media(min-device-width: 576px) and (max-device-width: 767px) {
}

/* Extra Small & Small */
@media(max-device-width: 767px) {
}

/* Medium (tablet in portrait) */
@media(min-device-width: 768px) and (max-device-width: 991px) {
}

/* Large (tablet in landscape/small desktop browser) */
@media(min-device-width: 992px) and (max-device-width: 1199px) {
}

/* Extra Large (full screen desktop) */
@media(min-device-width: 1200px) {
}
