.StyleBox {
    padding-inline: 10px;
    padding-block: 20px;
    display: inline-block;
    vertical-align: top;

    h1 {
        font-family: 'Dawning of a New Day', cursive;
    }

    img {
        width: 100%;
        border-radius: 10px;
    }

    p {
        margin-block-start: 8px;
        margin-block-end: 0;
    }
}

/* XXS-S */
@media(max-device-width: 767px) {
    .StyleBox {
        width: 320px;
        max-width: 90vw;
    }
}

/* M+ */
@media(min-device-width: 768px) {
    .StyleBox {
        width: calc(40vw - 60px);
        max-width: 400px;
    }
}
