@import "~bootstrap/scss/bootstrap";

.container, .container-fluid, .row, .col {
  padding: 0;
  margin: 0;
}

div[class*="col-"] {
  padding: 0;
  margin: 0;
}

.btn-link {
  padding-block: 0;
}